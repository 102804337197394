/* eslint-disable max-len */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiActions } from '../../../bus/actions';
import * as Yup from 'yup';
import { Button, Typography, Box } from '@mui/material';
import { api } from '../../../Api';
import { saveSession } from '../../../instruments';
import { useTranslation } from 'react-i18next';  // Імпортуємо useTranslation


// Схема для логіну
const LoginSchema = Yup.object().shape({
    username: Yup.string()
        .min(2, 'Username is too short')
        .max(30, 'Username is too long')
        .required('Username is required'),
    password: Yup.string()
        .min(2, 'Password is too short')
        .max(30, 'Password is too long')
        .required('Password is required'),
});

// Схема для реєстрації
const SignupSchema = Yup.object().shape({
    username: Yup.string()
        .min(2, 'Username is too short')
        .max(30, 'Username is too long')
        .required('Username is required'),
    password: Yup.string()
        .min(6, 'Password is too short')
        .max(30, 'Password is too long')
        .required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
});

function AuthForm(props) {
    const { onClose } = props;
    const { t } = useTranslation();  // Використовуємо useTranslation
    const [isSend, setIsSend] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [isLogin, setIsLogin] = useState(true); // Перемикання між формою логіну та реєстрації
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        confirmPassword: '',
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const showModal = (e) => {
        setIsModal(true)
    };
    const hideModal = (e) => {
        setIsModal(false)
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSend(true);
        const endpoint = isLogin ? '/login' : '/signup'; // Вибираємо ендпоінт залежно від режиму
        const data = await api.postData(formData, endpoint);

        if (data && data.token) {
            saveSession(data);
            dispatch(apiActions.setIsLogged(true));
            dispatch(apiActions.setUserData(data));
            let url = '/admin/user/' + data._id;
            if (data.role === 'admin') {
                url = '/admin/users';
            }
            navigate(url);
        } else if (data && data.error) {
            setError(data.error);
            setIsSend(false);
        }
    };

    const handleSendEmail = async () => {
        if (!email.trim()) {
            alert('Введіть email!');
            return;
        }

        try {
            const response = await api.postData(
                { email: email},
                '/email',
            );

            if (response.success) {
                alert(t('mail.mailOk'));
                hideModal(); 
            } else {
                alert(t('mail.mailError'));

            }
        } catch (err) {
            console.error('Помилка відправки запиту:', err);
        }
    };

    return (
        <Box sx={{ backgroundColor: '#fff', padding: '40px 20px', width: '100%', height: '70vh', display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '50%', margin: '20px auto' }}>
                <form style={{ width: '80%', margin: 'auto' }} onSubmit={handleSubmit}>
                    <Box mb={2}>
                        <label htmlFor="username" style={{ display: 'block', color: '#2060E9', marginBottom: '8px' }}>
                            {t('auth.username')}
                        </label>
                        <input
                            style={{
                                border: '3px solid #2060E9',
                                borderRadius: '25px',
                                width: '100%',
                                padding: '10px',
                            }}
                            id="username"
                            name="username"
                            type="text"
                            value={formData.username}
                            onChange={handleInputChange}
                            required
                        />
                    </Box>

                    <Box mb={2}>
                        <label htmlFor="password" style={{ display: 'block', color: '#2060E9', marginBottom: '8px' }}>
                            {t('auth.password')}
                        </label>
                        <input
                            style={{
                                border: '3px solid #2060E9',
                                borderRadius: '25px',
                                width: '100%',
                                padding: '10px',
                            }}
                            id="password"
                            name="password"
                            type="password"
                            value={formData.password}
                            onChange={handleInputChange}
                            required
                        />
                    </Box>

                    {!isLogin && (
                        <Box mb={2}>
                            <label htmlFor="confirmPassword" style={{ display: 'block', color: '#2060E9', marginBottom: '8px' }}>
                                {t('auth.confirmPassword')}
                            </label>
                            <input
                                style={{
                                    border: '3px solid #2060E9',
                                    borderRadius: '25px',
                                    width: '100%',
                                    padding: '10px',
                                }}
                                id="confirmPassword"
                                name="confirmPassword"
                                type="password"
                                value={formData.confirmPassword}
                                onChange={handleInputChange}
                                required
                            />
                        </Box>
                    )}

                    {error && (
                        <Box mb={2}>
                            <Typography color="error" variant="body2">
                                {error}
                            </Typography>
                        </Box>
                    )}

                    <Box mt={2}>
                        <Button
                            fullWidth
                            color="primary"
                            disabled={isSend}
                            type="submit"
                            sx={{ borderRadius: '25px', padding: '15px' }}
                            variant="contained"
                        >
                            {isLogin ? t('auth.login') : t('auth.signup')}
                        </Button>
                    </Box>

                    <Box mt={2} textAlign="center">
                        <Button onClick={() => setIsLogin(!isLogin)}>
                            {isLogin ? t('auth.noAccount') : t('auth.haveAccount')}
                        </Button>
                    </Box>
                    <Box mt={2} textAlign="center">
                        <Button onClick={() => showModal()}>
                            {t('auth.forgotPassword')}
                        </Button>
                    </Box>
                </form>
            </div>
            {isModal && (
                <div className='isModal'>
                    <div className='closeIsModal' onClick={() => hideModal()}>&times;</div>
                    <h2>{t('passwordReminder.title')}</h2>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: '30px 0' }}>
                        <input placeholder={t('passwordReminder.placeholder')}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}></input>
                        <button onClick={handleSendEmail}>{t('passwordReminder.send')}</button>
                    </div>

                </div>
            )}

        </Box>
    );
}

export default AuthForm;

