import React from 'react';
import ListConstructor from '../ListConstructor';
import { listing } from './listing';
import { Balance } from './balance';


function UsersList(props) {

    const CustomComponents = {
        balance: Balance,
    };
    return  (
        <>
            <div className = 'account'>
                <ListConstructor
                    { ...props }
                    isShowAddBtn
                    fields = { listing.list }
                    limit = { 200 }
                    CustomComponents = { CustomComponents }
                    sortDefault = 'createdAt'
                    sortOrderDefault = { false }
                    type = { 'users' }
                    urlApi = { '/users' }
                />
            </div>
        </>

    );
}

export default UsersList;
