import React from 'react';
import './style.css'
import { useTranslation } from 'react-i18next';

function AboutUs() {
  const { t } = useTranslation();
  return (
    <div className='aboutUsContent' style={{ color: '#fff', fontWeight: 'bold', padding: '40px' }}>
      <h3>{t('aboutUs.aboutUsTitle1')}</h3>

      <p>
        {t('aboutUs.paragraph1')}
      </p>
      <h3>{t('aboutUs.aboutUsTitle2')}</h3>
      <p style={{ marginLeft: '10px' }}>
        {t('aboutUs.paragraph2')}
      </p>

      <h3>{t('aboutUs.aboutUsTitle3')}</h3>

      <p>
        {t('aboutUs.paragraph3')}
      </p>
      <p>{t('aboutUs.paragraph4')}</p>

      <p>{t('aboutUs.paragraph5')}</p>
    </div>
  );
}

export default AboutUs;
