import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { book } from '../../../instruments';
import UsersList from '../Users/list';


export const UsersRouter = (props) => {
    return (
        <Routes>
            <Route
                exact
                element = {
                    <UsersList
                        { ...props }
                    />
                }
                path = { book.admin.users }
            />
        </Routes>
    );
};
