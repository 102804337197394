import React, { useState, useEffect } from 'react';
import './socialIcon.css';
import blogger from './icon/blogger.png';
import discord from './icon/discord.png';
import facebook from './icon/facebook.png';
import gmail from './icon/gmail.png';
import google from './icon/google.png';
import instagram from './icon/instagram.png';
import media from './icon/media.png';
import networks from './icon/networks.png';
import pinterest from './icon/pinterest.png';
import skype from './icon/skype.png';
import telegram from './icon/telegram.png';
import tiktok from './icon/tiktok.png';
import twitter from './icon/twitter.png';
import whatsapp from './icon/whatsapp.png';
import youtube from './icon/youtube.png';
import gitHub from './icon/gitHub.png';
import removebg from './icon/removebg.png';


const icons = [
    blogger, discord, facebook, gmail, google,
    instagram, media, networks, pinterest, skype,
    telegram, tiktok, twitter, whatsapp, youtube,gitHub,removebg
];

function SocialIcons() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const itemsToShow = 10;

    const visibleIcons = Array.from({ length: itemsToShow }, (_, i) => {
        const index = (currentIndex + i) % icons.length;
        return icons[index];
    });

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % icons.length);
        }, 2000); 

        return () => clearInterval(interval); 
    }, []);

   

    return (
        <div className="social-icons-container">
           
            <div className="icons">
                {visibleIcons.map((icon, index) => (
                    <img key={index} src={icon} alt={`icon-${index}`} className="icon-img" />
                ))}
            </div>
           
        </div>
    );
}

export default SocialIcons;
