import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { book } from '../../instruments';
import Login from '../Account/Login/login';
import Home from '../Home';
import AboutUs from '../AboutUs';
import TermsOfUse from '../TermsOfUse';
import PrivacyPolicy from '../PrivacyPolicy';
import Contact from '../Contact';
export const GlobalRouter = (props) => {
    return (
        <Routes>
            <Route
                exact
                element = {
                    <Home
                        { ...props }
                    />
                }
                path = { book.home }
            />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/terms" element={<TermsOfUse />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/contact" element={<Contact />} />
  
            <Route
                exact
                element = {
                    <Login
                        { ...props }
                    />
                }
                path = { book.login }
            />
        </Routes>
    );
};
