/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { api } from '../../../Api';
import ListItem from './item';
import ListFilter from './filter';
import { Add } from '@mui/icons-material';
import { TableRow, TableHead, TableBody, Table, IconButton, Box, TableCell, Button } from '@mui/material';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { IconButtonS, IconS, thTextStyle, thStyle, styleFilter } from './style';
import ThreeStateCheckbox from './ThreeStateCheckbox';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Switch from '@mui/material/Switch';

const selectState = (state) => ({
    socket:   state.swapi.socket,
    userData: state.swapi.userData,
});

function ListConstructor(props) {
    const state = useSelector(selectState);
    const  userData  = state.userData;

    const { dataBack = {}, fields, limit = 15, listSelect = {}, sortDefault = '_id', sortOrderDefault = false,  urlApi,  type, search, setCallBackData, setCallBack,
        isShowAddBtn, isDefaultFilter, CustomComponentsFilter,
        filterOutside = {},
        selectedAllTxt, selectedAllStaus } = props;
    const [ page, setCurrentPage ] = useState(1);
    const [ list, setList ] = useState([]);
    const [ listSelects, setListSelects ] = useState(listSelect);
    const [ totalPagges, setTotal ] = useState(0);
    const [ edit, setEdit ] = useState(null);
    const { user } = useParams();
    const [ loading, setLoading ] = useState(false);
    const [ clear, setClear ] = useState(false);
    const [ sortOrder, setSortOrder ] = useState(sortOrderDefault);
    const [ filter, setFilter ] = useState(filterOutside);
    const [ sort, setSort ] = useState(sortDefault ? sortDefault : 'createdAt');
    const [ isAdd, setIsAdd ] = useState(false);
    const [ defaultItem, setDefaultItem ] = useState(false);
    const [ componentName, setComponentName ] = useState(false);
    const [ handleSort, setHandleSort ] = useState('');
    const [ status, setStatus ] = useState(false);
    const [ open, setOpen ] = useState(false);
    const [ _isDefaultFilter, setDefaultFilter ] = useState(isDefaultFilter);


    useEffect(() => {
        setCurrentPage(1);
        const defaultItem = {
            _id: 'new',
        };
        const defaultFilter = {};
        for (const el of Object.keys(fields)) {
            if (fields[ el ].type !== 'edit' && fields[ el ].type !== 'btn' && fields[ el ].type !== 'delete') {
                defaultItem[ el ] = el !== '_id' ? null : defaultItem._id;
                if (fields[ el ].defaultFilter) {
                    defaultFilter[ el ] = fields[ el ].defaultFilter;
                }
                if (fields[ el ].type === 'arrSelect') {
                    defaultItem[ el ] = [];
                }
            }
        }
        setFilter(defaultFilter);
        setDefaultItem(defaultItem);
        let _componentName = 'admin/' + type;
        setComponentName(_componentName);
    }, [ fields ]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose =  async (confirmed) => {
        setOpen(false);
        if (confirmed) {
            setStatus((prevStatus) => !prevStatus);
            const newList = list.map((item) => {
                return {
                    ...item,
                    status: !status,
                };
            });
            setList(newList);

            await selectedAllStaus(!status, filter);
        }
    };


    const sortData = (value) => {
        if (sort === value) {
            setSortOrder(!sortOrder);
        } else {
            setSort(value);
        }
        setHandleSort(value);
    };

    const changePage = (page) => {
        setCurrentPage(page);
    };

    const setNewItem = (item) => {
        setList([ item, ...list ]);

        return true;
    };

    const getData = async (ispage = false) => {
        setLoading(true);
        const getData = {
            page:       page,
            sort:       sort,
            limit:      limit,
            sortOrder:  !!sortOrder,
            filterData: filter,
            search:     search,
            handleSort: handleSort,
            user:       user,
            type:       type,
            ...dataBack,
        };
        const data = await api.postData(getData, urlApi);
        if (setCallBackData && typeof setCallBack === 'function' && data) {
            setCallBackData(data);
        }
        if (data && data.sort) {
            setSort(data.sort.text);
            setSortOrder(data.sort.order);
        }

        if (data && data.list) {
            if (ispage) {
                let last_prev = list[ list.length - 1 ];
                let last = data.list[ data.list.length - 1 ];
                if (last_prev && last_prev.is_total && last && last.is_total) {
                    for (let key of Object.keys(last)) {
                        data.list[ data.list.length - 1 ][ key ] = Number(last_prev[ key ]) + Number(last[ key ]);
                    }
                }
                setList([ ...list, ...data.list ]);
            } else {
                setList(data.list);
            }
            if (data?.list?.[ 0 ]?.status) {
                setStatus(data.list[ 0 ].status);
            }
        } else {
            setList([]);
            setTotal(0);
        }
        if (data.total) {
            setTotal(Math.ceil(data.total / limit));
        } else {
            setTotal(0);
        }
        if (data && data.selectedList) {
            setListSelects({ ...listSelects, ...data.selectedList });
        }
        setLoading(false);
    };
    useEffect(() => {
        setClear(false);
    }, [ clear ]);

    useEffect(() => {
        if (Object.keys(filterOutside).length > 0) {
            setFilter(filterOutside);
        }
    }, [ filterOutside ]);

    useEffect(() => {
        if (typeof setCallBack === 'function') {
            setCallBack(filter);
        }
        if (_isDefaultFilter) {
            if (Object.keys(filter).length > 0) {
                getData();
            }
        } else {
            getData();
        }
    }, [
        _isDefaultFilter, sort, sortOrder, filter,
        search, type,
    ]);
    useEffect(() => {
        getData(page > 1);
    }, [ page ]);


    const filterJSX = fields
        ? Object.keys(fields).map((key) => {
            const field = fields[ key ];
            let show = true;

            if (field.onlyNew && !isAdd) {
                return null;
            }
            if (fields[ key ].roles && show) {
                show = fields[ key ].roles.includes(userData.role);
            }
            const width = field.width ? field.width + '%' : '10%';
            const StyleTd = { fontWeight: 500, fontSize: 13, width: width, padding: '5px 2px', align: 'center', verticalAlign: 'baseline' };

            let resultJSx = '';
            if (key === 'delete' || field.isClearFilter) {
                resultJSx = (
                    <TableCell
                        key = { key }
                        style = { StyleTd }>
                        <IconButton
                            sx = { IconButtonS }
                            onClick = { () => {
                                setFilter({});
                                setDefaultFilter(false);
                                setClear(!clear);
                                setSort(sortDefault ? sortDefault : 'createdAt');
                            } }>
                            <FilterAltOffIcon sx = { IconS } />
                        </IconButton>
                    </TableCell>
                );
            } else if (show) {
                let styleSort = {
                    transform: 'rotate(90deg)',
                };
                if (sort === key && sortOrder) {
                    styleSort = {
                        transform: 'rotate(-90deg)',
                    };
                }
                let CustomComponentFilter = null;
                if (field.customFilter && CustomComponentsFilter && CustomComponentsFilter[ key ]) {
                    CustomComponentFilter =  CustomComponentsFilter[ key ];
                }
                resultJSx = (
                    <TableCell

                        key = { key }
                        style = { StyleTd }>
                        <Box sx = { thStyle }>
                            {field.filter ? (
                                <ListFilter
                                    { ...props }
                                    clear = { clear }
                                    filter = { filter }
                                    label = { field.title  }
                                    list = { listSelects[ key ] ? listSelects[ key ] : [] }
                                    listSelects = { listSelects }
                                    name = { key }
                                    setCurrentPage = { setCurrentPage }
                                    setDefaultFilter = { setDefaultFilter }
                                    setFilter = { setFilter }
                                    type = { field.typeSearch ? field.typeSearch : key }
                                />
                            ) : (
                                <span style = { thTextStyle }>{field.title ? field.title : ''}</span>
                            )}
                        </Box>
                        {field.type === 'checkbox' ? (
                            <ThreeStateCheckbox
                                filter = { filter }
                                name = { key }
                                setFilter = { setFilter }
                            />
                        ) : ''}
                        {field.sort && field.type !== 'checkbox' ? (
                            <Box

                                className = { 'arrowBG'  }
                                sx = {{
                                    display:  'inline-block',
                                    height:   '10px',
                                    zIndex:   '10000',
                                    ...styleSort,
                                    position: 'relative',
                                    left:     'calc(50% - 5px)',
                                    margin:   '0 auto',
                                    width:    '10px',
                                    cursor:   'pointer',
                                }}
                                onClick = { () => field.sort &&  field.type !== 'checkbox'  ? sortData(key) : null }>
                            </Box>
                        ) : ''}
                        {CustomComponentFilter
                            ? (
                                <CustomComponentFilter
                                    field = { key }
                                    game = { props.game }
                                />
                            )
                            : ''}
                    </TableCell>

                );
            }

            return resultJSx;
        })
        : null;

    const listJSX = list && list.length > 0 ? list.map((item) => {
        return (
            <React.Fragment
                key = { item._id }>

                <ListItem
                    { ...props }
                    isParent
                    componentName = { componentName }
                    data = { item }
                    defaultItem = { defaultItem }
                    edit = { edit }
                    filter = { filter }
                    listSelects = { listSelects }
                    setEdit = { setEdit }
                    setIsAdd = { setIsAdd }
                    userData = { userData }

                />


            </React.Fragment>
        );
    }) : null;

    return (
        <>

            <Box sx = { styleFilter }>
                <Box sx = {{ flex: '1' }}>
                    {isShowAddBtn  ? (
                        <Button
                            color = { isAdd ? 'warning' : 'inherit' }
                            variant = 'contained'
                            onClick = { () => {
                                setIsAdd(!isAdd);
                                setEdit('new');
                            } }>

                            {isAdd ? (
                                <> {' Відмінити'}</>
                            ) : (<> <Add sx = { IconS } /> {' Додати'}</>)}

                        </Button>
                    ) : ''}


                </Box>

                <Box sx = {{ flex: 1, display: 'flex', alignItems: 'center', gap: '10px' }}>
                    {selectedAllTxt && (
                        <>
                            <Stack
                                direction = 'row'
                                spacing = { 2 }>
                                <Button
                                    color = { status ? 'primary' : 'secondary' }
                                    variant = 'contained'
                                    onClick = { handleClickOpen }>
                                    {status ? 'Виключити ' + selectedAllTxt : 'Включити ' + selectedAllTxt}
                                </Button>
                                <Switch
                                    checked = { status }
                                    onChange = { handleClickOpen }
                                />

                                <Dialog
                                    open = { open }
                                    onClose = { () => handleClose(false) }>
                                    <DialogTitle>{"Підтвердіть дію"}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            Ви впевнені, що хочете {status ? 'виключити' : 'включити'} {selectedAllTxt}?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            color = 'primary'
                                            onClick = { () => handleClose(false) }>
                                            Скасувати
                                        </Button>
                                        <Button
                                            autoFocus
                                            color = 'primary'
                                            onClick = { () => handleClose(true) }>
                                            Підтвердити
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Stack>
                        </>
                    )}
                </Box>


            </Box>

            <Box className = { `loading ${loading ? 'active' : ''}` }>
                Loading...
            </Box>

            <Table>
                <TableHead>
                    <TableRow>
                        {filterJSX}


                    </TableRow>
                </TableHead>
                <TableBody>
                    {isAdd ? (
                        <ListItem
                            { ...props }
                            componentName = { componentName }
                            data = { defaultItem }
                            defaultItem = { defaultItem }
                            edit = { edit }
                            filter = { filter }
                            isAdd = { isAdd }
                            key = { defaultItem._id }
                            listSelects = { listSelects }
                            setEdit = { setEdit }
                            setIsAdd = { setIsAdd }
                            setNewItem = { setNewItem }
                            userData = { userData }


                        />
                    ) : null}

                    {listJSX}
                </TableBody>
            </Table>
            {totalPagges  > 1 ? (
                <div style = {{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Button
                        sx = {{ margin: '0 10px' }}
                        onClick = { ()=>changePage(page + 1) }>
                        Загрузити ще
                    </Button>
                </div>
            ) : ''}

        </>
    );
}


export default ListConstructor;
