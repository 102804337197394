import React, { useState, useEffect } from 'react';
import { Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material'; // Іконки для редагування та збереження
import { api } from '../../../Api';
import editIcon from './icon/edit.png';
import credit_card from './icon/credit_card.png';

const PersonalInfo = ({ data, t }) => {
    const [isEdit, setIsEdit] = useState(false);
    const [dataLocal, setDataLocal] = useState({});
    const [formData, setFormData] = useState({
        name: data.name,
        email: data.email,
        phone: data.phone,
        address: data.address,
    });
    const urlArr = window.location.pathname.split('/');
    const id = urlArr[urlArr.length - 1];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [balance, setBalance] = useState('');
    const [paymentData, setPaymentData] = useState(null);

    useEffect(() => {
        setDataLocal(data);
    }, [data]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSave = async () => {
        try {
            const updatedData = await api.postData({ data: formData, type: 'user' }, '/user/save/' + data._id);
            if (updatedData._id) {
                setDataLocal(updatedData);
                setIsEdit(false);
            } else {
                console.log('Error saving data');
            }
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    const handleCancel = () => {
        setFormData({
            name: dataLocal.name,
            email: dataLocal.email,
            phone: dataLocal.phone,
            address: dataLocal.address,
        });
        setIsEdit(false);
    };

    const handleInputChange = (e) => {
        setBalance(e.target.value);
    };

    const handlePaymentSelection = async (paymentType) => {
        if (paymentType === 'up_balance' && balance > 0) {
            try {
                const transactionData = await api.postData(
                    { userId: id, amount: balance },
                    '/payment/create-crypto-transaction',
                );
                if (transactionData) {
                    setPaymentData(transactionData);
                }
            } catch (error) {
                console.error("Error creating crypto transaction:", error);
            }
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setPaymentData(null);
        setBalance('');
    };

    return (
        <section className='personal-info'>
            <h2>{t('personalInfo')}</h2>
            <div style={{ display: 'flex' }}>
                <div className='personalInfoContent'>
                    {/* Особиста інформація */}
                    {['name', 'email', 'phone', 'address'].map((field) => (
                        <div className='personalInfoItem' key={field}>
                            <strong>{t(field)}:</strong>
                            {!isEdit ? (
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className='personalInfoEl'>{dataLocal[field]}</div>
                                    <img
                                        onClick={() => setIsEdit(true)}
                                        className="edit"
                                        alt="/"
                                        src={editIcon}
                                    />
                                </div>
                            ) : (
                                <input
                                    type="text"
                                    name={field}
                                    value={formData[field]}
                                    onChange={handleChange}
                                />
                            )}
             
                        </div>
                    ))}
                </div>

                {/* Баланс */}
                <div className='personalInfoItemBalance'>
                    <strong>{t('balance')}:</strong>
                    <div className='item_balance'>{dataLocal.balance}$</div>
                    <div className='refillBalance' onClick={() => setIsModalOpen(true)}>
                        <img
                            className="edit"
                            alt="/"
                            src={credit_card}
                        />
                        {t('selectPaymentMethod')}
                    </div>
                </div>
            </div>

            {/* Кнопки редагування */}
            {isEdit ? (
                <div className='edit-buttons'>
                    <button onClick={handleSave} className='save'>
                        <SaveIcon />
                        {t('save')}
                    </button>
                    <button onClick={handleCancel} className='cancel'>
                        {t('cancel')}
                    </button>
                </div>
            ) : null}

            {/* Модалка для поповнення балансу */}
            {isModalOpen && (
                <div className='modal'>
                    <div className='modal_top_up_balance'>

                        {!paymentData ? (
                            // Модалка з полем введення суми
                            <section className='payment-selection'>
                                <button
                                    className="close-button"
                                    onClick={closeModal}
                                >
                                    ✖
                                </button>
                                <h2>{t('selectPaymentMethod')}</h2>
                                <input
                                    className='top_up_balance'
                                    placeholder={t('payment_summ')}
                                    value={balance}
                                    onChange={handleInputChange}
                                />
                                <div className='payment-buttons'>
                                    <button onClick={() => handlePaymentSelection('up_balance')}>
                                        {t('payWithCrypto')}
                                    </button>
                                </div>
                            </section>
                        ) : (
                            // Модалка з інформацією про транзакцію
                            <div className='modal-content'>
                                <button
                                    className="close-button"
                                    onClick={closeModal}
                                >
                                    ✖
                                </button>
                                <h2>{t('paymentInformation')}</h2>
                                <p><strong>{t('amount')}:</strong> {paymentData.amount}</p>
                                <p><strong>{t('transactionId')}:</strong> {paymentData.txn_id}</p>
                                <p><strong>{t('address')}:</strong> {paymentData.address}</p>
                                {paymentData.qrcode_url && (
                                    <div className='qr-code'>
                                        <h3>{t('scanToPay')}</h3>
                                        <img alt={t('qrCodeAlt')} src={paymentData.qrcode_url} />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </section>
    );
};

export default PersonalInfo;
