import React from 'react';
import './footer.css';
import { useTranslation } from 'react-i18next';
import discord from './icon/discord.png';
import facebook from './icon/facebook.png';
import mail from './icon/mail.png';
import skype from './icon/skype.png';
import telegram from './icon/telegram.png';
import youtube from './icon/youtube.png';

import { Link } from 'react-router-dom';  // Імпортуємо Link з react-router-dom




function Footer() {
    const { t } = useTranslation();

    return (
        <>
            <div className="footerContent">

                <div className='footerItem'>

                    <h3>{t('footer.company.title')}</h3>

                    <p><Link to={'/about'}>
                        {t('footer.company.item1')}
                    </Link></p>
                    <p><Link to={'/contact'}>
                        {t('footer.company.item2')}
                    </Link></p>
                    <p> <Link to={'/privacy'}>
                        {t('footer.company.item3')}
                    </Link></p>
                    <p> <Link to={'/terms'}>
                        {t('footer.company.item4')}
                    </Link></p>
                </div>
                <div className='footerItem'>
                    <h3>{t('footer.customerCenter.title')}</h3>
                    <div>
                        <a href='mailto:easytovpn.com@gmail.com'>easytovpn.com@gmail.com</a>
                    </div>
                </div>

                <div className='footerItem'>
                    <h3>{t('footer.contacts')}</h3>
                    <div>

                        <a href="mailto:easytovpn.com@gmail.com">
                            <img src={mail} alt='mail'></img>
                        </a>
                        <a
                            href="https://t.me/easytovpn"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="contact-link"
                        ><img src={telegram} alt='telegram'></img>
                        </a>
                        <img src={facebook} alt='facebook'></img>

                        <a
                            href="https://join.skype.com/invite/Fjxc9DRvApoo"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="contact-link"
                        ><img src={skype} alt='skype'></img>
                        </a>

                    </div>
                </div>
                <div className='footerItem'>
                    <h3>{t('footer.ourSocialMedia')}</h3>
                    <div>
                        <a
                            href="https://t.me/easytovpn"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="contact-link"
                        ><img src={telegram} alt='telegram'></img>
                        </a>
                        <img src={discord} alt='discord'></img>
                        <img src={youtube} alt='youtube'></img>
                        <img src={facebook} alt='facebook'></img>
                    </div>
                </div>
            </div >
            <div className="footer-copyright">© 2024 VPN Service. All rights reserved.</div>

        </>
    );
}

export default Footer;
