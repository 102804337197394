import React, { useState } from 'react';
import './iconCountry.css';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import france from './icon/france.png';
import great_britain from './icon/great_britain.png';
import netherlands from './icon/netherlands.png';
import left from './icon/left.png';
import right from './icon/right.png';


const selectState = (state) => ({
    userData: state.swapi.userData,
});


function IconCountry() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const { t } = useTranslation();
    const state = useSelector(selectState);
    const { userData } = state;
    const countries = [
       
        { name: t('country.great_britain'), icon: great_britain },
        { name: t('country.france'), icon: france },
        { name: t('country.netherlands'), icon: netherlands },

    ];

    const handleScroll = (direction) => {
        if (direction === 'l') {
            setCurrentIndex((prevIndex) => (prevIndex - 1 + countries.length) % countries.length);
        } else if (direction === 'r') {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % countries.length);
        }
    };

    const getVisibleIcons = () => {
        const visibleIcons = [];
        for (let i = -1; i <= 1; i++) {
            const index = (currentIndex + i + countries.length) % countries.length;
            visibleIcons.push(countries[index]);
        }
        return visibleIcons;
    };

    return (
        <>
            <div className="icon-container">
                <img
                    src={left}
                    alt="left"
                    className="icon-scroll-btn l"
                    onClick={() => handleScroll('l')}
                />
                <div className="icon-list">
                    {getVisibleIcons().map((country, index) => (
                        <div
                            key={index}
                            className={`icon-item ${index === 1 ? 'center' : ''}`}
                        >
                            <img
                                src={country.icon}
                                alt={country.name}
                                className={`icon-image ${index === 1 ? 'center' : ''}`}
                            />
                            <div
                                className={`icon-country-name ${index === 1 ? 'center' : ''}`}
                            >
                                {country.name}
                            </div>
                        </div>
                    ))}
                </div>
                <img
                    src={right}
                    alt="right"
                    className="icon-scroll-btn r"
                    onClick={() => handleScroll('r')}
                />
            </div>
            <Link to={userData?._id ? '/admin/user/' + userData?._id : '/login'}>
                <button className='btn_link_country'>{t('banner.country')}</button>
            </Link>
        </>
    );
}

export default IconCountry;
