import React from 'react';
import './description.css';
import { useTranslation } from 'react-i18next';
import header from './header.png';




function Description() {
    const { t } = useTranslation();


    return (
        <div>
            <div className='description_header'>
                <h1>{t('descriptionVPN.title')}</h1>
                <img style = {{width: '50px', marginRight: '10px'}} src={header} alt=''></img>
                EasyToVPN
            </div>
            <div className='description_content'>
                <div className='content_item'><strong>{t('descriptionVPN.descriptionItem1.title')}</strong>{t('descriptionVPN.descriptionItem1.text')}</div>
                <div className='content_item1'><strong>{t('descriptionVPN.descriptionItem2.title')}</strong>{t('descriptionVPN.descriptionItem2.text')}</div>
                <div className='content_item'><strong>{t('descriptionVPN.descriptionItem3.title')}</strong>{t('descriptionVPN.descriptionItem4.text')}</div>
                <div className='content_item1'><strong>{t('descriptionVPN.descriptionItem4.title')}</strong>{t('descriptionVPN.descriptionItem3.text')}</div>
            </div>
        </div>
    );
}

export default Description;
