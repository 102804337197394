// eslint-disable-line
import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { animateScroll } from 'react-scroll';
import { apiActions } from '../../bus/actions';
import Header from '../Header';
import { AccountRouter } from '../Account/Router';
import history from '../Router/history';
import { getUserData } from '../../instruments';
import { getLanguage } from '../../instruments';
import '../../i18n';  // Додаємо цей імпорт у головний файл
import '../../theme/styles/accountMui.css';
import { GlobalRouter } from '../Router';
import Footer from '../Footer';

const selectState = (state) => ({
    isFetching: state.swapi.isFetching,
    isLogged: state.swapi.isLogged,
});


export const Main = () => {
    const state = useSelector(selectState);
    const isLogged = state.isLogged;
    const dispatch = useDispatch();

    useEffect(() => {
        try {
            const userData = getUserData();
            const lang = getLanguage();
            dispatch(apiActions.setLanguage(lang || 'en'));
            console.log(lang)
            if (userData) {
                dispatch(apiActions.setIsLogged(true));
                dispatch(apiActions.setUserData(userData));
            } else {
                dispatch(apiActions.setIsLogged(false));
                dispatch(apiActions.setUserData(null));
            }
        } catch (error) {
            //
        }
        // eslint-disable-next-line
    }, []);


    return (
        <Router history={history}>
            <div className={`wrapper ${isLogged ? 'logged' : ''}`}>
                <div className={`header_wrap body_wrap with_bg with_header `}>
                    <Header />
                </div>
                <div className={`content_wrap  `}>
                    <div
                        className='content'
                        id='content'>
                        {isLogged && (
                            <AccountRouter />
                        )}
                        <GlobalRouter />

                    </div>
                </div>
                <div className='footer'>
                    <Footer/>
                </div>
            </div>
            <div
                className='bTop'
                onClick={() => { animateScroll.scrollToTop({ duration: 200 }); }}>
                <span />
            </div>
        </Router>
    );
};
