import React from 'react';
import './style.css'
import { useTranslation } from 'react-i18next';

function PrivacyPolicy() {
  const { t } = useTranslation();
  return (
    <div className='privacyPolicy' style={{ color: '#fff', fontWeight: 'bold', padding: '40px' }}>
      <h1>{t('privacyPolicy.title')} EasyToVPN</h1>
      <h2>{t('privacyPolicy.introduction.title')}</h2>
      <p>{t('privacyPolicy.introduction.text')}</p>
      <p>{t('privacyPolicy.introduction.text2')}</p>

      <h2>{t('privacyPolicy.informationCollected.title')}</h2>
      <h3>{t('privacyPolicy.informationCollected.text')}</h3>
      <p>{t('privacyPolicy.informationCollected.accountInformation.title')}</p>
      <ul>

        <li>{t('privacyPolicy.informationCollected.accountInformation.details.email')}</li>
        <li>{t('privacyPolicy.informationCollected.accountInformation.details.name')}</li>
        <li>{t('privacyPolicy.informationCollected.accountInformation.details.paymentInfo')}</li>
        <li>{t('privacyPolicy.informationCollected.accountInformation.details.paymentAmount')}</li>
        <li>{t('privacyPolicy.informationCollected.accountInformation.details.cardInfo')}</li>
        <li>{t('privacyPolicy.informationCollected.accountInformation.details.country')}</li>
        <li>{t('privacyPolicy.informationCollected.accountInformation.details.payerEmail')}</li>
        <li>{t('privacyPolicy.informationCollected.accountInformation.details.text')}</li>
      </ul>

      <p>{t('privacyPolicy.informationCollected.communicationData.title')}</p>
      <ul>
        <li>{t('privacyPolicy.informationCollected.communicationData.details.email')}</li>
        <li>{t('privacyPolicy.informationCollected.communicationData.details.deviceInfo')}</li>
        <li>{t('privacyPolicy.informationCollected.communicationData.details.chatData')}</li>
        <li>{t('privacyPolicy.informationCollected.communicationData.details.text')}</li>
        <li>{t('privacyPolicy.informationCollected.communicationData.details.services_and_features')}</li>
        <li>{t('privacyPolicy.informationCollected.communicationData.details.browser_type')}</li>
        <li>{t('privacyPolicy.informationCollected.communicationData.details.diagnostic_data')}</li>

      </ul>

      <h2>{t('privacyPolicy.howWeUseYourInformation.title')}</h2>
      <p>{t('privacyPolicy.howWeUseYourInformation.text')}</p>
      <ul>
        <li>{t('privacyPolicy.howWeUseYourInformation.usageDetails.manageAccount')}</li>
        <li>{t('privacyPolicy.howWeUseYourInformation.usageDetails.improveServices')}</li>
        <li>{t('privacyPolicy.howWeUseYourInformation.usageDetails.paymentProcessing')}</li>
        <li>{t('privacyPolicy.howWeUseYourInformation.usageDetails.supportResponse')}</li>
        <li>{t('privacyPolicy.howWeUseYourInformation.usageDetails.usageAnalysis')}</li>
        <li>{t('privacyPolicy.howWeUseYourInformation.usageDetails.notifications')}</li>
      </ul>

      <h2>{t('privacyPolicy.informationSharing.title')}</h2>
      <p>{t('privacyPolicy.informationSharing.text')}</p>
      <ul>
        <li>{t('privacyPolicy.informationSharing.sharingDetails.trustedProviders')}</li>
        <li>{t('privacyPolicy.informationSharing.sharingDetails.legalAuthorities')}</li>
        <li>{t('privacyPolicy.informationSharing.sharingDetails.groupCompanies')}</li>
        <li>{t('privacyPolicy.informationSharing.sharingDetails.businessTransfer')}</li>
      </ul>

      <h2>{t('privacyPolicy.vpnServers.title')}</h2>
      <p>{t('privacyPolicy.vpnServers.text')}</p>
      <p>{t('privacyPolicy.vpnServers.warning')}</p>

      <h2>{t('privacyPolicy.dataStorage.title')}</h2>
      <p>{t('privacyPolicy.dataStorage.text')}</p>
      <ul>
        <li>{t('privacyPolicy.dataStorage.storageDetails.paymentInfo')}</li>
        <li>{t('privacyPolicy.dataStorage.storageDetails.marketingConsent')}</li>
        <li>{t('privacyPolicy.dataStorage.storageDetails.supportRequests')}</li>
      </ul>

      <h2>{t('privacyPolicy.userRights.title')}</h2>
      <p>{t('privacyPolicy.userRights.text')}</p>
      <ul>
        <li>{t('privacyPolicy.userRights.rightsDetails.access')}</li>
        <li>{t('privacyPolicy.userRights.rightsDetails.objection')}</li>
        <li>{t('privacyPolicy.userRights.rightsDetails.withdrawConsent')}</li>
        <li>{t('privacyPolicy.userRights.rightsDetails.complaint')}</li>
        <li>{t('privacyPolicy.userRights.rightsDetails.complaint_info')}</li>
      </ul>

      <h2>{t('privacyPolicy.securityMeasures.title')}</h2>
      <p>{t('privacyPolicy.securityMeasures.text')}</p>
      <ul>
        <li>{t('privacyPolicy.securityMeasures.securityDetails.encryption')}</li>
        <li>{t('privacyPolicy.securityMeasures.securityDetails.firewalls')}</li>
        <li>{t('privacyPolicy.securityMeasures.securityDetails.accessPolicies')}</li>
        <li>{t('privacyPolicy.securityMeasures.securityDetails.text')}</li>
      </ul>

      <h2>{t('privacyPolicy.internationalDataTransfers.title')}</h2>
      <p>{t('privacyPolicy.internationalDataTransfers.text')}</p>
      <p>{t('privacyPolicy.internationalDataTransfers.info')}</p>

      <h2>{t('privacyPolicy.businessTransfer.title')}</h2>
      <p>{t('privacyPolicy.businessTransfer.text')}</p>

      <h2>{t('privacyPolicy.childrenPolicy.title')}</h2>
      <p>{t('privacyPolicy.childrenPolicy.text')}</p>

      <h2>{t('privacyPolicy.policyUpdates.title')}</h2>
      <p>{t('privacyPolicy.policyUpdates.text')}</p>

      <h2>{t('privacyPolicy.contact.title')}</h2>
      <p>{t('privacyPolicy.contact.text')}</p>
      <ul>
        <li>{t('privacyPolicy.contact.details.email')}</li>
        <li>
          <a className="linkTelegram" href="https://t.me/vpnMyServiceBot" target="_blank" rel="noreferrer">
            {t('privacyPolicy.contact.details.telegram')}
          </a>
        </li>
      </ul>
      <p>{t('privacyPolicy.contact.details.lastUpdated')}</p>
    </div>
  );


}

export default PrivacyPolicy;
