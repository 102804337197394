const listing = {};

listing.list = {
    name: {
        type:      'text',
        title:     'Імя',
        width:     '8',
        filter:    'Input',
        component: 'Input',
    },
    balance: {
        type:      'custom',
        title:     'Баланс послуг',
        width:     '10',
        sort:      true,
        role_edit: [ 'admin' ],
    },
    delete: {
        type:   'delete',
        values: [ 'delete' ],
        width:  '1',
    },
};


export { listing };
