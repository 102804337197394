import React from 'react';
import './style.css'
import { useTranslation } from 'react-i18next';

function TermsOfUse() {
  const { t } = useTranslation();
  return (
    <div className='termsOfUse' style={{ color: '#fff', fontWeight: 'bold', padding: '40px' }}>
      <h1>{t('termsOfUse.title')} EasyToVPN</h1>
      
      <h2>{t('termsOfUse.introduction.title')}</h2>
      <p>{t('termsOfUse.introduction.text')}</p>
      <p>{t('termsOfUse.introduction.text2')}</p>
  
      <h2>{t('termsOfUse.ageRestrictionsAndRegistration.title')}</h2>
      <p>{t('termsOfUse.ageRestrictionsAndRegistration.text')}</p>
      <p>{t('termsOfUse.ageRestrictionsAndRegistration.registration')}</p>
  
      <h2>{t('termsOfUse.ourServices.title')}</h2>
      <p>{t('termsOfUse.ourServices.text')}</p>
      <p>{t('termsOfUse.ourServices.policy')}</p>
      <p>{t('termsOfUse.ourServices.responsibility')}</p>
  
      <h2>{t('termsOfUse.acceptableUse.title')}</h2>
      <p>{t('termsOfUse.acceptableUse.text')}</p>
      <ul>
        <li>{t('termsOfUse.acceptableUse.restrictions.illegalUse')}</li>
        <li>{t('termsOfUse.acceptableUse.restrictions.unauthorizedAccess')}</li>
        <li>{t('termsOfUse.acceptableUse.restrictions.spam')}</li>
        <li>{t('termsOfUse.acceptableUse.restrictions.serviceInterference')}</li>
      </ul>
      <p>{t('termsOfUse.acceptableUse.actions')}</p>
  
      <h2>{t('termsOfUse.fairUse.title')}</h2>
      <p>{t('termsOfUse.fairUse.text')}</p>
  
      <h2>{t('termsOfUse.subscriptionsAndPayments.title')}</h2>
      <p>{t('termsOfUse.subscriptionsAndPayments.text')}</p>
  
      <h2>{t('termsOfUse.refundPolicy.title')}</h2>
      <p>{t('termsOfUse.refundPolicy.text')}</p>
  
      <h2>{t('termsOfUse.intellectualProperty.title')}</h2>
      <p>{t('termsOfUse.intellectualProperty.text')}</p>
  
      <h2>{t('termsOfUse.disclaimerAndLimitationOfLiability.title')}</h2>
      <p>{t('termsOfUse.disclaimerAndLimitationOfLiability.text')}</p>
  
      <h2>{t('termsOfUse.indemnity.title')}</h2>
      <p>{t('termsOfUse.indemnity.text')}</p>
  
      <h2>{t('termsOfUse.termination.title')}</h2>
      <p>{t('termsOfUse.termination.text')}</p>
  
      <h2>{t('termsOfUse.serviceModifications.title')}</h2>
      <p>{t('termsOfUse.serviceModifications.text')}</p>
  
      <h2>{t('termsOfUse.governingLanguage.title')}</h2>
      <p>{t('termsOfUse.governingLanguage.text')}</p>
  
      <h2>{t('termsOfUse.contact.title')}</h2>
      <p>{t('termsOfUse.contact.text')}</p>
      <ul>
        <li>{t('termsOfUse.contact.details.email')}</li>
        <a className = 'link_chat' href='https://www.easytovpn.com'>{t('termsOfUse.contact.details.chat')}</a>
      </ul>
      <p>{t('termsOfUse.contact.details.lastUpdated')}</p>
    </div>
  );
  
}

export default TermsOfUse;
