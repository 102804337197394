import React, { useState } from 'react';
import { Snackbar, Alert } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faClone } from '@fortawesome/free-solid-svg-icons';

export const Text = (props) => {
    const { item, fieldKey, isCopy } = props;

    const [ isSnackbarOpen, setSnackbarOpen ] = useState(false);

    const copyToClipboard = async (text) => {
        if (isCopy) {
            try {
                await navigator.clipboard.writeText(text);
                setSnackbarOpen(true);
            } catch (error) {
                console.error("Failed to copy text: ", error);
            }
        }
    };

    return (
        <>
            <span  onClick = { ()=> copyToClipboard(item[ fieldKey ])  }>

                {item[ fieldKey ]?.label || item[ fieldKey ] || ''}
                <br/>

                {isCopy ? (
                    <FontAwesomeIcon
                        icon = { faClone }
                        style = {{ color: "#1976d2" }}
                    />) : ''}
            </span>
            <Snackbar
                autoHideDuration = { 3000 }
                open = { isSnackbarOpen }
                onClose = { () => setSnackbarOpen(false) }>
                <Alert
                    severity = 'success'
                    variant = 'filled'>
                    Text copied to clipboard!
                </Alert>
            </Snackbar>
        </>

    );
};
